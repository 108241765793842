@import "utils";
@import "light";
@import "dark";
@import "../ib/css/index.scss";

:root {
  @include light-theme;
}

html[theme="dark"] {
  @include dark-theme;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

html[lang="ja"] {
  font-family: "Noto Sans JP";
}
html[lang="zh-Hans"] {
  font-family: "Noto Sans SC";
}
html[lang="zh-Hant"] {
  font-family: "Noto Sans TC";
}
html[lang="th"] {
  font-family: "Noto Sans Thai";
}
html[lang="ar"] {
  font-family: "Noto Sans Arabic";
}
html[lang="vi"],
html[lang="el"] {
  font-family: "Noto Sans";
}
html[lang="en"],
html[lang="de"],
html[lang="es"],
html[lang="cs"],
html[lang="id"],
html[lang="ms-MY"],
html[lang="pt-BR"],
html[lang="pl"],
html[lang="it"] {
  font-family: "Figtree";
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  overflow-y: hidden;
  letter-spacing: normal;
  color: var(--text);
  background-color: var(--background);
  * {
    transition: background-color 0.5s ease;
  }
  caret-color: var(--text);
  line-height: 1.5;
  ///Firefox
  scrollbar-color: var(--color-4) transparent;
  scrollbar-width: thin;
}

a {
  font-weight: bolder;
  text-decoration: none;
}

h1 {
  font-size: 68px;
  font-weight: bold;
  line-height: 1.06;
}

h2 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.14;
  color: var(--text);
  margin: 32px 0;
}

h3 {
  font-size: 32px;
  font-weight: 600;
}

h4 {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.54;
}

#root {
  margin: 0 auto;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button.green,
button.green-outline,
button.gray-outline,
button.link {
  @include button(true);
  cursor: pointer;
  overflow: hidden;
}

span.link {
  cursor: pointer;
  font-weight: bold;
  color: var(--accent);
  text-decoration: underline;
}

button.green-outline {
  background-color: transparent;
  color: var(--accent);
  border: 1px solid var(--accent);

  &:hover {
    color: var(--button-hover);
    border-color: var(--button-hover);
    background-color: transparent;
  }
}

button.gray-outline {
  background-color: transparent;
  color: var(--text);
  border: 1px solid var(--color-5);
  &:hover {
    color: var(--text);
    border-color: var(--color-5);
    background-color: transparent;
  }
}

button.link {
  min-height: auto;
}

.amountClass {
  font-family:
    "Arial",
    -apple-system,
    system-ui,
    sans-serif;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.tooltip {
  font-size: 14px;
  opacity: 1 !important;
  background-color: var(--color-7) !important;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  min-width: 150px;
  max-width: 200px;
  color: var(--background);
  cursor: default;

  &.place-top:after {
    border-top-color: var(--color-7) !important;
  }

  &.place-right:after {
    border-right-color: var(--color-7) !important;
  }

  &.place-bottom:after {
    border-bottom-color: var(--color-7) !important;
  }

  &.place-left:after {
    border-left-color: var(--color-7) !important;
  }
}

.card {
  @include base-card;
}

.fullscreen-card {
  @include card;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.page {
  display: flex;
  justify-content: center;
}

.page-card {
  padding: 50px;
  @include base-card;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: $mobile) {
    padding: 20px;
  }
}

.page-content {
  position: relative;
  width: 100%;
  padding: 50px;

  .bottom-action {
    margin-top: 50px;
  }

  @media screen and (max-width: $tablet) {
    padding: 30px;
  }

  @media screen and (max-width: $mobile) {
    padding: 20px;
  }
}

.row2 {
  display: grid;
  width: 100%;

  @media screen and (min-width: $laptop) {
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
  }

  &.reverse {
    > *:first-child {
      grid-row: 2;
    }

    @media screen and (min-width: $laptop) {
      grid-template-columns: 50% 50%;
      grid-gap: 10px;

      > *:first-child {
        grid-row: 1;
      }
    }
  }
}

.column {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 30px;
    color: var(--text);
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
  }

  .content {
    font-size: 16px;

    text-align: center;
    color: var(--color-3);
  }

  .bottom {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.dropdown-menu {
  background-color: transparent;
  font-size: 16px;
  line-height: 34px;

  > * {
    @include extra-space-for-bold;
  }

  > *:hover {
    font-weight: bolder;
  }
}

.fade-enter {
  opacity: 0;
  // transform: scale(0.1);
}

.fade-enter-done {
  opacity: 1;
  // transform: scale(1);
}

.transition {
  // transition: all 300ms;
  width: 100%;
}

.error-under-control {
  // height: 20px;
  transition: opacity 300ms;

  .error-text {
    opacity: 1;
    margin-top: 7px;
    font-size: 14px;
    color: var(--error);
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
  }
}

iframe {
  border: none;
  width: 100%;
}

label,
input {
  transition: all 0.2s;
  touch-action: manipulation;
}

input {
  all: unset;
  height: 48px;
  padding: 0 16px;
  border: solid 1px var(--input-border-color);
  border-radius: 4px;
  background-color: var(--background);
  color: var(--text);
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: var(--input-border-color-focused) !important;
  }

  &:focus::placeholder {
    color: var(--text);
  }

  &:disabled {
    background-color: var(--background-disabled);
  }

  &:disabled::placeholder {
    color: var(--color-3);
  }

  &.error {
    border-color: var(--error);
    box-shadow: none;
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    height: 24px;
    color: var(--color-4);

    @media screen and (max-width: $mobile) {
      font-size: 14px;
    }
  }
}

input[type="password"]:not(:placeholder-shown) {
  font-family: caption;
}

.form-gray-text {
  font-size: 14px;
  color: var(--color-3);
}

.error {
  font-size: 14px;
  color: var(--error);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

#root[screen="mobile"] {
  ::-webkit-scrollbar {
    width: 0;
  }
}

///Chrome, Edge, Safari and Opera
::-webkit-scrollbar-thumb {
  background: var(--color-4);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--highlight);
}

.last-child-margin > div {
  &:last-child {
    margin-bottom: 20px;
  }
}

.faq-note {
  color: lightcoral;
}

@media screen and (max-width: $mobile) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 26px;
    margin: 16px 0;
  }
}

.caption {
  display: flex;

  .hint {
    margin-inline-start: 15px;

    &::before {
      content: "?";
    }

    @include hint;
  }
}

@include ie11-styles {
  .row2 {
    display: flex;
    flex-direction: column;

    > *:first-child {
      margin-bottom: 10px;
    }

    &.reverse {
      flex-direction: column-reverse;

      > *:first-child {
        margin-top: 10px;
      }
    }
  }

  .hint {
    margin-left: 15px;

    @include rtl-styles {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}

.grid2 {
  @include grid2(22px);

  &.mobileCollapse {
    @media screen and (max-width: $laptop) {
      display: block;
    }
  }
}

.form-tip {
  font-size: 12px;
  color: var(--color-3);

  &.up {
    margin-top: -20px;
  }
}

#launcher {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

///signal centre buttons color
div[class*="TradingElementSingleTab__opBottonDesk"] {
  color: #fff !important;
  font-weight: normal !important;
}

.error-note {
  color: var(--error);
  font-size: smaller;

  &::before {
    content: "*";
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
