@import "../../../../css/utils";

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  &.tt {
    .web,
    .mobile {
      grid-column: 1 / span 2;
      display: flex;
      gap: 20px;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: column;
  }
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    font-size: 26px;
    font-weight: bold;
  }
}

.sub-title {
  font-size: 14px;
  color: var(--color-3);
  margin-top: 8px;
}

.page-title {
  display: flex;
  gap: 20px;
  align-items: center;

  img {
    height: 56px;
    width: 56px;
  }
}

.buttons {
  display: flex;
  gap: 30px;

  @media screen and (max-width: $tablet) {
    gap: 16px;
    display: grid;
    justify-items: center;
  }
}

.button {
  display: flex;
  justify-content: center;
}
.platform-card {
  p {
    margin-block: 2em;
  }

  &.vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.qrCodeSection {
  display: flex;
  gap: 20px;

  &.centered {
    justify-content: center;
  }
}

.qrCode {
  position: relative;
  > img {
    width: 112px;
    height: 112px;
    z-index: 10;
  }
  .bigQR {
    > img {
      width: min(400px, 90vw);
    }
    z-index: 10;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
  }
  &:hover .bigQR {
    visibility: visible;
  }
}

.ttweb {
  width: 450px;
}

.tvNav {
  display: flex;
  align-items: end;
  span {
    margin-inline-start: 10px;
  }
}
.tv-card {
  @include grid2;
  button {
    margin-bottom: 32px;
  }
  .icons {
    position: relative;
    .tt-icon,
    .tv-icon {
      position: absolute;
      bottom: 16px;
      img {
        width: 96px;
        height: 96px;
      }
    }
    .tt-icon {
      left: 220px;
      transform: rotate(20deg);
    }
    .tv-icon {
      left: 160px;
      transform: rotate(-20deg);
    }
  }
}
.tv {
  display: grid;
  gap: 32px;
  margin-bottom: 32px;
}
.youtube {
  display: flex;
  width: fit-content;
  height: 48px;
  padding: 0 16px;
  align-items: center;
  border: 1px solid var(--color-3);
  border-radius: 8px;
  cursor: pointer;
  gap: 16px;
  svg {
    width: 24px;
    height: 24px;
  }
}
.alert {
  border-radius: 10px;
  margin-bottom: 32px;
  padding: 16px 24px;
  font-size: 14px;
  background-color: var(--card-banner-background);
}

.sc-card {
  @include grid2;
  button {
    margin-top: 32px;
  }
  margin-bottom: 64px;
  img {
    justify-self: end;
  }
  @media screen and (max-width: $tablet) {
    img {
      justify-self: center;
    }
  }
}
